import React from "react";
import { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import HistoryCard from "./HistoryCard";

const HistorySection = ({ history }) => {
  const containerRef = useRef(null);

  const sliderArray = history?.More_Detail || [];

  const [numColumns, setNumColumns] = useState(sliderArray.length);
  const [width, setWidth] = useState(0);
  const dragSlider = useRef();

  useEffect(() => {
    setWidth(dragSlider.current.scrollWidth - dragSlider.current.offsetWidth);
    const handleScroll = (e) => {
      containerRef.current.scrollLeft += e.deltaY;
      e.preventDefault();
      
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  return (
    <div className="historySection" ref={containerRef}>
      <div className="historyBox">
        <motion.div className="sliderItems" ref={dragSlider}>
          <motion.div
            ref={dragSlider}
            className="sliderItem"
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${numColumns}, 20.4rem)`,
              gap: "1rem",
            }}
          >
            {sliderArray.map((item, index) => (
              <HistoryCard key={index} item={item}></HistoryCard>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default HistorySection;
