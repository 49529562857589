import React from "react";

// SCSS
// import "./cta.scss";

const CTASection = () => {
  return (
    <div className="CTASection">
      <div className="CTAContainer">
        <div className="CTAImage">
            <img src={process.env.PUBLIC_URL + "/images/Section/Group17.png"} alt=""></img>
        </div>
        <div className="CTAContent">
            <h4>Step into the</h4>
            <h1>Future</h1>
            <h5>of Home Textiles</h5>
            <p>Virtually Try Our Bedsheets with Augmented Reality</p>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
