import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const Slider = ({ product }) => {
  return (
    <>
      <div className="sliderBox">
        <div className="sliderContainer">
          <div className="sliderContent">
            <h2>{product.Content_Title}</h2>
            <p>{product.Content}</p>
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {product.More_Detail.map((d, index) => (
              <SwiperSlide
                key={index}
                style={{
                  // backgroundImage: `url('${d.image.url}')`,
                  backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.0),rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.9)),url('${d.Image}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {d.Title}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Slider;
