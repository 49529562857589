import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import ContactUsSection from "../component/contact/ContactUsSection";
import BannerFour from "../component/banner/BannerFour";
import Introduction from "../component/introduction/Introduction";
import Gallery from "../component/gallery/Gallery";

// Loader
import Loader from "react-js-loader";

const CSR = () => {
  const [page, setPage] = useState(null);
  const [section1, setSection1] = useState(null);
  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState(null);
  const [section4, setSection4] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const pageData = await fetch(
          "https://unionapi.kidznteen.pk/public/api/pages/5"
        );
        if (!pageData.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await pageData.json();

        const pageContent = await result.find((page) => page.Page_Id === 17);

        setPage(pageContent);

        setSection1(
          await pageContent.Sections.find((section) => section.PH_Id === 29)
        );

        setSection2(
          await pageContent.Sections.find((section) => section.PH_Id === 30)
        );

        setSection3(
          await pageContent.Sections.find((section) => section.PH_Id === 31)
        );

        setSection4(
          await pageContent.Sections.find((section) => section.PH_Id === 32)
        );

        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 16)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <SEO title={page.Page_Title} description={page.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <Introduction introduction={section1.Content} />
        <div className="history-sections">
          <div className="history-section-establishment">
            <div className="width-1200">
              <div className="contianer-row">
                <div className="history-section-heading">
                  <h4>{section2.Content_Title}</h4>
                  <p>{section2.Content}</p>
                </div>
                <div className="history-heading-image">
                  <img src={section2.Image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(to bottom, #DA9351 80%, #fff)",
            textAlign: "center",
            color: "var(--color-white)",
            padding: "20px",
          }}
        >
          <h4
            style={{
              color: "var(--color-white)",
              margin: "0px",
              fontSize: "var(--h4)",
            }}
          >
            {section3.Content_Title}
          </h4>
          <Introduction
            color={`var(--color-white)`}
            introduction={section3.Content}
          ></Introduction>
        </div>

        <Introduction introduction={section4.Content} />

        <Gallery images={section4.More_Detail} />

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default CSR;
