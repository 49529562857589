import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import ContactUsSection from "../../component/contact/ContactUsSection";
import BannerFour from "../../component/banner/BannerFour";
import ProductIntroduction from "../../component/products/ProductIntroduction";
import Introduction from "../../component/introduction/Introduction";
import Gallery3 from "../../component/gallery/Gallery3";
import ProcessSection from "../../component/process/Process";
import Gallery4 from "../../component/gallery/Gallery4";

// Loader
import Loader from "react-js-loader";

const HomeTextiles = () => {
  const [page, setPage] = useState(null);
  const [section1, setSection1] = useState(null);
  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState(null);
  const [section4, setSection4] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const pageData = await fetch(
          "https://union.kidznteen.pk/public/api/pages/3/4"
        );
        if (!pageData.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await pageData.json();

        const pageContent = await result.find((page) => page.Page_Id === 7);

        setPage(pageContent);

        setSection1(
          await pageContent.Sections.find((section) => section.PH_Id === 12)
        );
        setSection2(
          await pageContent.Sections.find((section) => section.PH_Id === 13)
        );
        setSection3(
          await pageContent.Sections.find((section) => section.PH_Id === 14)
        );
        setSection4(
          await pageContent.Sections.find((section) => section.PH_Id === 15)
        );

        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 8)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      <SEO title={page.Page_Title} description={page.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <ProductIntroduction
          heading={section1.Head_Title}
          introduction={section1.Content}
          color={"var(--color-text-dark)"}
        ></ProductIntroduction>

        {/* Section 02 */}

        <div className="history-sections">
          <div className="history-section-establishment">
            <div className="width-1200">
              <div className="contianer-row">
                <div className="history-section-heading">
                  <h4>{section2.Head_Title}</h4>
                  <p>{section2.Content}</p>
                </div>
                <div className="history-heading-image">
                  <img src={section2.Image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Gallery3 images={section2.More_Detail}></Gallery3>

        {/* Section 03 */}

        <div className="history-sections pb-4">
          <div
            className="history-section-establishment"
            style={{ backgroundColor: "white" }}
          >
            <div className="width-1200">
              <div className="history-section-machinery">
                <h4>{section3.Head_Title}</h4>
                <div className="history-section-machinery-images">
                  <div className="width-30">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={section3.More_Detail[0].Image}
                        alt={section3.More_Detail[0].Title}
                      />
                      <p>{section3.More_Detail[0].Title}</p>
                    </div>
                  </div>
                  <div className="width-20">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={section3.More_Detail[1].Image}
                        alt={section3.More_Detail[1].Title}
                      />
                      <p>{section3.More_Detail[1].Title}</p>
                    </div>
                  </div>
                  <div className="width-50">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={section3.More_Detail[2].Image}
                        alt={section3.More_Detail[2].Title}
                      />
                      <p>{section3.More_Detail[2].Title}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="history-sections pb-4">
          <div
            className="history-section-establishment"
            style={{ backgroundColor: "white" }}
          >
            <div className="width-1200">
              <div className="home-textiles">
                <div className="heading">
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: section3.Content_Title.replace(/\s+/g, "<br/>"),
                    }}
                  ></h4>
                </div>
                <div className="desc">
                  <p>{section3.Content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section 04 */}

        <div
          style={{
            background: "linear-gradient(to bottom, #DA9351 80%, #fff)",
            textAlign: "center",
            color: "var(--color-white)",
            padding: "20px",
          }}
        >
          <h4
            style={{
              color: "var(--color-white)",
              margin: "0px",
              fontSize: "var(--h4)",
            }}
          >
            {section4.Content_Title}
          </h4>
          <Introduction
            color={`var(--color-white)`}
            introduction={section4.Content}
          ></Introduction>
        </div>

        <div className="history-sections pb-4">
          <div
            className="history-section-establishment"
            style={{ backgroundColor: "white" }}
          >
            <div className="width-1200">
              <ProcessSection process={section4} />
            </div>
          </div>
        </div>

        <Gallery4 images={section2.More_Detail}></Gallery4>

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default HomeTextiles;
