import React from "react";

const Introduction = ({introduction, color}) => {
  return (
    <>
      <div className="intro-wrapper">
        <div className="intro-container">
          <div className="intro">
            <p style={{color:color}}>{introduction}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
