import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import ContactUsSection from "../../component/contact/ContactUsSection";
import BannerFour from "../../component/banner/BannerFour";
import CertificateSection from "../../component/certifications/Certificate";
import Introduction from "../../component/introduction/Introduction";
import Gallery from "../../component/gallery/Gallery";

// Loader
import Loader from "react-js-loader";

const Awards = () => {
  const [content, setContent] = useState(null);
  const [awards, setAwards] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const response2 = await fetch(
          "https://union.kidznteen.pk/public/api/pages/2/3"
        );
        if (!response2.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await response2.json();
        const awardPage = await result.find((page) => page.Page_Id === 6);

        setContent(awardPage);

        setAwards(
          await awardPage.Sections.find((section) => section.PH_Id === 11)
        );

        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 5)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <SEO title={content.Page_Title} description={content.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />

        <Introduction introduction={awards.Content} />

        <div className="awards-sections">
          <div className="awards-section-introduction">
            <div className="width-1200">
              <CertificateSection></CertificateSection>
            </div>
          </div>
        </div>

        <Gallery images={awards.More_Detail} />

        {/* <div className="awards-sections mb-5 pb-5">
          <div className="awards-section-introduction">
            <div className="width-1200">
              <CertificateSection></CertificateSection>
              <div className="awards-cards pt-5">
                {awardsBanner.More_Detail.map((award, index) => (
                  <AwardCard key={index} {...award} />
                ))}
              </div>
            </div>
          </div>
        </div> */}

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default Awards;
