import React from "react";

const GalleryCard = ({ Image, Title, Content }) => (
  <div className="gallery-card">
    <img src={process.env.PUBLIC_URL + Image} alt={Content} />
  </div>
);

const Gallery = ({ images }) => {
  return (
    <>
      <div className="gallery-section">
        <div className="gallery-section-introduction">
          <div className="gallery-container">
            <div className="gallery-cards pt-4">
              {images.map((award, index) => (
                <GalleryCard key={index} {...award} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
