import React from "react";

// import components
// import FormOne from "./FormOne";
// import FormTwo from "./FormTwo";
// import FormThree from "./FormThree";
import FormFour from "./FormFour";

const ContactUsSection = () => {
  return (
    <>
      <div className="contactContainer">
        <div className="contactHeading">
          <div className="contactImage">
            <img src={process.env.PUBLIC_URL + "/images/Section/Group21.png"} alt=""></img>
          </div>
          <div className="contactTitle">
            Contact Us
          </div>
        </div>
        <div className="contactForm">
          <div className="contactFormContainer">
            <FormFour></FormFour>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsSection;
