import React, { useEffect, useState } from "react";
import SEO from "../common/SEO";
import ContactUsSection from "../component/contact/ContactUsSection";
import BannerFour from "../component/banner/BannerFour";
import FormTwo from "../component/contact/FormTwo";
import { FaEnvelope } from "react-icons/fa";
import { FaHeadphones } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";

// Loader
import Loader from "react-js-loader";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 19)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      <SEO
        // title="{content.Page_Title}"
        // description="{content.Meta_Description}"
        title="Contact Us"
        description="Union Fabric"
      />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <div className="contact-us-section ">
          <div className="left">
            <div className="content">
              <div className="content-form">
                <h5 className="form-heading" style={{ color: "black" }}>
                  Let's Connect
                </h5>
                <p style={{ color: "black" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  vel consequat massa. Mauris feugiat ligula sed elit sodales,
                  at bibendum leo varius. Curabitur tincidunt urna ut ante
                  feugiat, in varius nulla mattis. Quisque et commodo nunc.
                  Vestibulum ante ipsum primis in faucibus orci luctus et
                  ultrices posuere cubilia
                </p>
                <div className="contact-details">
                  <div className="detail">
                    <FaEnvelope />
                    <p style={{ color: "black" }}>Additional information</p>
                  </div>
                  <div className="detail">
                    <FaHeadphones />
                    <p>Additional information</p>
                  </div>
                  <div className="detail">
                    <FaLocationArrow />
                    <p style={{ color: "black" }}>Additional information</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="content">
              <div className="content-form">
                <FormTwo></FormTwo>
              </div>
            </div>
          </div>
        </div>

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default ContactUs;
