import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import ContactUsSection from "../../component/contact/ContactUsSection";
import BannerFour from "../../component/banner/BannerFour";
import ProductIntroduction from "../../component/products/ProductIntroduction";
import Gallery2 from "../../component/gallery/Gallery2";
import Introduction from "../../component/introduction/Introduction";
import Gallery from "../../component/gallery/Gallery";

// Loader
import Loader from "react-js-loader";

const DyedPrinted = () => {
  const [page, setPage] = useState(null);
  const [productIntro, setProductIntro] = useState(null);
  const [productIntro2, setProductIntro2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const pageData = await fetch(
          "https://union.kidznteen.pk/public/api/pages/3/5"
        );
        if (!pageData.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await pageData.json();

        const pageContent = await result.find((page) => page.Page_Id === 8);

        setPage(pageContent);

        setProductIntro(
          await pageContent.Sections.find((section) => section.PH_Id === 16)
        );
        setProductIntro2(
          await pageContent.Sections.find((section) => section.PH_Id === 17)
        );

        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 7)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      <SEO title={page.Page_Title} description={page.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <ProductIntroduction
          heading={productIntro.Content_Title}
          introduction={productIntro.Content}
          color={"var(--color-text-dark)"}
        ></ProductIntroduction>

        <Gallery2 images={productIntro.More_Detail}></Gallery2>
        <Introduction introduction={productIntro2.Content}></Introduction>
        <Gallery images={productIntro2.More_Detail}></Gallery>
        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default DyedPrinted;
