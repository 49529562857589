import React from "react";

const ProductIntroduction = ({ heading, introduction, color }) => {
  return (
    <>
      <div className="product-intro-wrapper">
        <div className="product-intro-container">
          <div className="product-intro">
            <h4>{heading}</h4>
            <p style={{ color: color }}>{introduction}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductIntroduction;
