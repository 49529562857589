import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

const FooterThree = () => {
  return (
    <footer>
      <div className="footerThree">
        <div className="footerThree-container">
          <div className="footerThree-logo">
            <img src="/images/logo.png" alt="logo"></img>
            <p>
              Union Fabrics (Pvt.) Limited intends to be the best manufacturer
              of the textile products by assuring quality in products, service
              and enviromental aiming at customers' stakeholders' satisfaction
              by complying with regulatory and other requirements for continual
              improvement.
            </p>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/who-we-are/history"}>About Us</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact-us"}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/products/home-textiles"}>Products</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/corporate-social-responsibility"}>Corporate Social Responsibility (CSR)</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/exhibition"}>
                  Exhibitions
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/careers"}>
                  Careers
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    Privacy Policies
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
              <ul className="footermenu-icons">
              <li>
                  <Link target="_blank" to="https://www.facebook.com/unionfabrics/">
                    <img
                      width={30}
                      height={30}
                      src={process.env.PUBLIC_URL + "/images/icon/facebook.png"}
                      alt="facebook"

                    ></img>                    
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.linkedin.com/company/unionfabrics/">
                    <img
                      width={30}
                      height={30}
                      src={process.env.PUBLIC_URL + "/images/icon/linkedin.png"}
                      alt="linkedin"

                    ></img>
                    
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://twitter.com/Unionfabric">
                    <img
                      width={30}
                      height={30}
                      src={process.env.PUBLIC_URL + "/images/icon/twitter.png"}
                      alt="x"

                    ></img>
                    
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="copyrights">
          <p>Designed by</p>
          <img src="/images/systechlogic-logo.png" width={100} alt="systech"></img>
        </div>
      </div>
    </footer>
  );
};

export default FooterThree;
