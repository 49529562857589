import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import ContactUsSection from "../../component/contact/ContactUsSection";
import BannerFour from "../../component/banner/BannerFour";
import Introduction from "../../component/introduction/Introduction";

// Loader
import Loader from "react-js-loader";

const Stitching = () => {
  const [page, setPage] = useState(null);
  const [section1, setSection1] = useState(null);
  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const pageData = await fetch(
          "https://union.kidznteen.pk/public/api/pages"
        );
        if (!pageData.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await pageData.json();
        const pageContent = await result.find((page) => page.Page_Id === 14);

        setPage(pageContent);

        setSection1(
          await pageContent.Sections.find((section) => section.PH_Id === 26)
        );
        setSection2(
          await pageContent.Sections.find((section) => section.PH_Id === 27)
        );
        setSection3(
          await pageContent.Sections.find((section) => section.PH_Id === 28)
        );

        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 14)
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      <SEO title={page.Page_Title} description={page.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <Introduction
          introduction={section1.Content}
          color={"var(--color-text-dark)"}
        ></Introduction>

        <div className="history-sections">
          <div className="history-section-establishment">
            <div className="width-1200">
              <div className="contianer-row">
                <div className="history-section-heading">
                  <h4>{section2.Content_Title}</h4>
                  <p>{section2.Content}</p>
                </div>
                <div className="history-heading-image">
                  <img src={section2.Image} alt="" />
                </div>
              </div>

              <div className="history-section-machinery pt-5 pb-5">
                <div className="history-section-machinery-images">
                  <div className="history-section-machinery-images-img">
                    <img
                      src={section2.More_Detail[0].Image}
                      alt={section2.More_Detail[0].Title}
                    />
                  </div>
                  <div className="history-section-machinery-images-img">
                    <img
                      src={section2.More_Detail[1].Image}
                      alt={section2.More_Detail[1].Title}
                    />
                  </div>
                  <div className="history-section-machinery-images-img">
                    <img
                      src={section2.More_Detail[2].Image}
                      alt={section2.More_Detail[2].Title}
                    />
                  </div>
                  <div className="history-section-machinery-images-img">
                    <img
                      src={section2.More_Detail[3].Image}
                      alt={section2.More_Detail[3].Title}
                    />
                  </div>
                </div>
              </div>

              <div className="history-section-machinery pt-5">
                <h4>{section3.Content_Title}</h4>
                <div className="history-intro">
                  <p>{section3.Content}</p>
                </div>
                <div className="history-section-machinery-images">
                  <div className="width-30">
                    <img
                      src={section3.More_Detail[0].Image}
                      alt={section3.More_Detail[0].Title}
                    />
                  </div>
                  <div className="width-20">
                    <img
                      src={section3.More_Detail[1].Image}
                      alt={section3.More_Detail[1].Title}
                    />
                  </div>
                  <div className="width-50">
                    <img
                      src={section3.More_Detail[2].Image}
                      alt={section3.More_Detail[2].Title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default Stitching;
