import React from "react";

const CertificateSection = () => {
  const certificateImages = [
    { src: process.env.PUBLIC_URL + "/images/certifications/1.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/2.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/3.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/4.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/5.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/6.jpg" },
    { src: process.env.PUBLIC_URL + "/images/certifications/7.jpg" },
    // Add more image paths as needed
  ];

  return (
    <div className="certificate-wrapper">
      <div className="certificates">
        <h2>Certifications</h2>
        <div className="certificate-grid">
          {certificateImages.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={`Certificate ${index + 1}`}
              className="certificate-img"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CertificateSection;
